/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// import DataTable from "examples/Tables/DataTable";
import projectsTableData from "layouts/tables/data/projectsTableData";

import Select from 'react-select';
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { NumericFormat } from 'react-number-format';

import DataTable from 'react-data-table-component';

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import {Post,Put} from "../../tools/Helper";
import Message from "../../tools/Message";

function ServicioForm({editar, data, setShow, getLista, tipos}) {

	const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});

	useEffect(()=>{
    setFormData(data);
    setStatus({status: null, message: ""});
  },[data,setFormData]);

  const handleChange = (e, name = "", value = "") =>{
    console.log(e)
    if(name === ""){
      setFormData({...formData, [e.target.name]:e.target.value});
    }else{
      setFormData({...formData, [value]:e[value], [name]:e});
    }
  }

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const tempData = formData;

    const request = editar ? await Put(`servicios/${formData.id_servicio}`,formData) : await Post('servicios',formData) ;

    if(request.status === "success"){
      await getLista();
      if(editar){
        setFormData(tempData);
      }
    }
    setStatus(request);
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    {editar ? "Actualizar" : "Agregar"} Servicio
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : <><Grid item xs={2} >
                        <MDInput type="text" label="Clave" name="clave"
                            error={verifyError("clave")} errorMsg={getError("clave")}
                            value={formData.clave} onChange={event => handleChange(event)} fullWidth />
                      </Grid>
                      <Grid item xs={2} >
                        <MDInput type="text" label="Clave SAT" name="clave_prod_sat"
                            error={verifyError("clave_prod_sat")} errorMsg={getError("clave_prod_sat")}
                            value={formData.clave_prod_sat} onChange={event => handleChange(event)} fullWidth />
                      </Grid>
                      <Grid item xs={4} >
                        <MDInput multiline type="textarea" pt={5} rows={3} label="Descripcion" name="descripcion"
                            error={verifyError("descripcion")} errorMsg={getError("descripcion")}
                            value={formData.descripcion} onChange={event => handleChange(event)} fullWidth />
                      </Grid>
                      <Grid item xs={2} >
                        <small className="font-red">{getError("id_unidad")}</small>
                        <Select className={`z-10 ${verifyError("id_unidad") ? "border-red" : ""}`}
                            options={tipos} placeholder="Unidad..."
                            value={formData.unidad} onChange={(e) => handleChange(e,"unidad","id_unidad")}
                            getOptionValue={(option) => `${option.id_unidad}`}
                            getOptionLabel={(option) => `${option.nombre}`}
                          />
                        {/*
                          <MDInput type="text" label="Tipo" name="id_unidad"
                            error={verifyError("id_unidad")} errorMsg={getError("id_unidad")}
                            value={formData.id_unidad} onChange={event => handleChange(event)} fullWidth />

                        */}
                      </Grid>
                      <Grid item xs={2}>
                      <NumericFormat
                        value={formData.p_unit}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          setFormData({
                            ...formData, 
                            'p_unit': value
                          });
                        }}
                        prefix='$'
                        suffix='.00'
                        customInput={MDInput}
                        thousandSeparator=','
                        label="Precio"
                        name="p_unit"
                        error={verifyError("p_unit")}
                        helperText={getError("p_unit")}
                        fullWidth
                      />
                      </Grid>
                      <Grid item xs={6}>
                        <MDButton variant="gradient" color="info" disabled={loading}
                            onClick={()=>doRequest()} fullWidth>
                            {editar ? "Actualizar" : "Agregar"}
                          </MDButton>
                      </Grid>
                      </>}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
	);
}

ServicioForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  tipos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default ServicioForm;