import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";

import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Select from 'react-select';
import { Divider, Icon } from "@mui/material";
import { parseCurrency } from "tools/utils";
import { BorderStyle } from "@mui/icons-material";

function Servicios({serviciosArray, setServiciosArray, catalogoServicios, errors, setStatus, typeForm,  getSumPriceUnit, editOss = 'false'}) {
  
  const [servicio, setServicio] = useState({
    cantidad: '',
    servicio: []
  });

  function randomString(longitud) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
  
    for (let i = 0; i < longitud; i+= 1) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(indiceAleatorio);
    }
  
    return id;
  }

	const add = () => {
    if(servicio.cantidad === '' || servicio.servicio.length === 0){
      return;
    }
		setServiciosArray([
        ...serviciosArray,
          {
            ... servicio, 
            id_element: randomString(8),
            importe: parseInt(servicio.cantidad, 10) * parseFloat(servicio.servicio.p_unit)
          }
      ]);
		setServicio({
      cantidad: '',
      servicio: []
    });
    getSumPriceUnit();
	}

	const remove = (idElement, type) =>{

    if(type=== 'element'){      
      const newData = serviciosArray.filter(item=> item.id_element !== idElement);
      setServiciosArray(newData);
      return;
    }

    const newData = serviciosArray.filter(item=> item.id_pivot !== idElement);
    setServiciosArray(newData);
	}
  
	const handleChange = (event, index, name = "", value = "") => {
		  if(name === "servicio"){
        setServicio({
          ...servicio, 
            [name]:event,
          });
	    }else{
        setServicio({...servicio, [event.target.name]:event.target.value});
	    }

    if(errors!==null){
      const errName = name === "" ? `servicios.${index}.${event.target.name}` : `servicios.${index}.${name}`;
      if(errName in errors){
        const temp = errors;
        delete temp[errName];
        setStatus(status => ({...status, errors:temp}));
      }
    }

	}

  const verifyError = (name, index) =>{
    if(errors !== null){
      const errName = `servicios.${index}.${name}`;
      console.log(errName);
      if(errName in errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name, index) =>{
    if(errors !== null){
      const errName = `servicios.${index}.${name}`;
      console.log(errName);
      if(errName in errors){
        return errors[errName][0];
      }
    }

    return "";
  }

  const handleChangeEdit =(e, index)=>{
    const updatedServiciosArray = [...serviciosArray];
    const updatedServicio = { ...updatedServiciosArray[index].servicio };
    updatedServicio.descripcion = e.target.value;
    updatedServiciosArray[index].servicio = updatedServicio;
    setServiciosArray(updatedServiciosArray);
  }

  const handleChangeEditAmount =(e, index)=>{
    const updatedServiciosArray = [...serviciosArray];
    updatedServiciosArray[index] = {
      ...updatedServiciosArray[index],
      importe: e.target.value
    };
    setServiciosArray(updatedServiciosArray);
    getSumPriceUnit(updatedServiciosArray);
  }

  useEffect(()=>{
    setServiciosArray([]);
  },[])

return(
	<Fade in timeout={800}>
    <Grid container spacing={2} p={5}>
        <Grid item xs={12}>
          <MDTypography variant="h5" pb={1}>
            Conceptos
          </MDTypography>
        </Grid>
        {
          typeForm === 'cotizacion' || editOss === 'true' ?
          <>
            <Grid item xs={2}>
              <MDBox mb={2}>
                <MDInput type="number" label="Cantidad" name="cantidad"
                  error={verifyError("cantidad")} errorMsg={getError("cantidad")}
                  value={servicio.cantidad}
                  onChange={handleChange} fullWidth
                />
              </MDBox>
            </Grid>
            <Grid item xs={8} mt="auto">
              <MDBox mb={2}>
                <small className="font-red">{getError("servicio")}</small>
                <Select className={`z-10 z-auto ${verifyError("servicio") ? "border-red" : ""}`}
                  options={catalogoServicios} placeholder="Seleccione servicio"
                  value={servicio.servicio} onChange={(e) => handleChange(e,"servicio","servicio")}
                  getOptionValue={(option) => `${option.id_servicio}`}
                  getOptionLabel={(option) => `${option.descripcion}`}
                />
              </MDBox>
            </Grid>
              <Grid item xs={2} mt="auto">
                <MDBox mb={2}>
                  <MDButton variant="gradient" color="info" 
                    onClick={()=>add()} fullWidth>
                    Agregar
                  </MDButton>
                </MDBox>
            </Grid>
            </>
          : null
        }
        {
          typeForm === 'cotizacion' ?
          <>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <MDBox mb={2}>
                  <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center'}}>
                    <b>Cantidad</b>
                  </p>
                </MDBox>
              </Grid>
              <Grid item md={7}>
                  <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5}}>
                    <b>Concepto</b>
                  </p>
              </Grid>
                <Grid item container xs={1} alignItems="center">
                      <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right'}}>
                        <b>P. Unit.</b>
                      </p>
                </Grid>
                <Grid item container xs={1} alignItems="center">
                    <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right'}}>
                      <b>Importe</b>
                    </p>
                </Grid>
                <Grid item xs={1} mt="auto">
                  <MDBox mb={2} />
                </Grid>
            </Grid>
            <hr style={{ BorderStyle: 'solid', borderWidth: 1, borderColor: 'gray', width: '100%', marginBottom: 10 }} />
          </>
        : null
        }
        {
          serviciosArray ?
          serviciosArray.map((item, index)=>(
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <MDBox mb={2}>
                  <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'center'}}>
                    {item.cantidad}
                  </p>
                </MDBox>
              </Grid>
              <Grid item md={7}>
                {
                  typeForm === 'cotizacion' ?
                    <textarea rows={4} md={12} style={{ width: '100%', borderColor: '#c8c8c8', padding: 6, borderRadius: 10 }} 
                      value={item.servicio.descripcion}
                      onChange={(e)=>handleChangeEdit(e, index)}
                    />
                  : 
                  <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5}}>
                    {item.servicio.descripcion} 
                  </p>

                }
              </Grid>
              {
                typeForm === 'cotizacion' ?
                <>
                  <Grid item container xs={1} alignItems="center">
                    {
                      typeForm === 'cotizacion' ?
                        <MDInput type="number" label="" name="importe"
                          error={verifyError("importe")} errorMsg={getError("importe")}
                          value={item.importe}  onChange={(e)=>handleChangeEditAmount(e, index)} fullWidth />
                      :
                        <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right'}}>
                          {parseCurrency((parseFloat(item.servicio.p_unit) * parseInt(item.cantidad, 10)).toFixed(2))} 
                        </p>
                    }
                  </Grid>
                  <Grid item container xs={1} alignItems="center">
                    {
                      typeForm === 'cotizacion' ?
                      <p style={{fontSize: 15, paddingLeft: 20, paddingBottom: 5, textAlign: 'right'}}>
                        {parseCurrency((parseFloat(item.importe) * parseInt(item.cantidad, 10)).toFixed(2))} 
                      </p>
                      :
                        null
                    }
                  </Grid>
                  </>
                : null
              }
              {
                typeForm === 'cotizacion' ?
                  <Grid item xs={1} mt="auto">
                    <MDBox mb={2}>
                      <MDButton variant="gradient" color="error" 
                        onClick={()=>remove(item.id_element ?? item.id_pivot, item.id_element ? 'element' : 'pivot')}>
                        <Icon>delete</Icon>
                      </MDButton>
                    </MDBox>
                  </Grid>
                : null
              }
          </Grid>
          ))
          : null
        }
    </Grid>
  </Fade>);
}

Servicios.propTypes = {
  serviciosArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  catalogoServicios: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setServiciosArray: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  getSumPriceUnit: PropTypes.func.isRequired,
  typeForm: PropTypes.string.isRequired,
  editOss: PropTypes.string.isRequired,
};

export default Servicios;