/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { PDFDownloadLink, pdf } from '@react-pdf/renderer';  
import PDFGenerator from "../../tools/PDFGenerator";

import Servicios from '../tables/Servicios';

import {Get,Put,PostMulti} from "../../tools/Helper";
import Message from "../../tools/Message";

function OssForm({data, setShow, getLista, show}) {

	const animatedComponents = makeAnimated();
  const scrollRef = useRef(React.createRef());

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;


	const [formData, setFormData] = useState({
	    numero:'',
	    num_cotizacion:'',
	    fecha_oss:datestring,
	    elabora:'',
	    elabora_puesto:'',
	    acepta:'',
	    responsables: undefined,
	    info_adicional:'',
	    comentario: 'La presente orden de servicio y/o suministros (OSS) define implícitamente un deber para Paymun S.A. de C.V. de realizar/suministrar los servicios/productos descritos en el Apartado B, así mismo; implica el deber del Contratante (Apartado A) de realizar el pago correspondiente de acuerdo a los términos comerciales consentidos por ambas partes, en el entendido que al momento de emitir la presente OSS el contratante ha dado su consentimiento y autorización para realizar/suministrar los servicios/productos solicitados mediante la cotización referenciada.',
	    direccion:'',
      estado:'',
      municipio:'',
    });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [serviciosArray, setServiciosArray] = useState([]);
  const [servicios, setServicios] = useState([]);


  const getDataCatalogos = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Get('catalogos');

    if(request.status === "success"){
      setServicios(request.servicios);
    }else{
      setStatus(request);
    }

  }


	useEffect(()=>{
    setFormData(data);
    getDataCatalogos();
    if(data.servicios){
    	setServiciosArray(data.servicios);
    }
    setStatus({status: null, message: ""});
  },[data,setFormData]);

	const [clientes, setClientes] = useState([]);
  const [responsables, setResponsables] = useState([]);

  const [catalogosJson, setCatalogosJson] = useState("");


  const setServArr = (d) =>{
    setServiciosArray(d);
  }

  useEffect(()=>{
  	const getData = async e =>{
    
	    setStatus({status: null, message: ""});
	    setLoading(true);

	    const request = await Get('catalogos');

	    if(request.status === "success"){
	      setClientes(request.clientes);
	      // setServicios(request.servicios);
	      setResponsables(request.responsables);
        setCatalogosJson(JSON.stringify(request));
	    }else{
	      setStatus({status:request.status, message:"Error."});
	    }

	    setLoading(false);
	  }

    getData();
  },[]);

  const handleChange = (e, name = "", value = "") =>{
    console.log(name);
    if(name === ""){
      setFormData({...formData, [e.target.name]:e.target.value});
    }else{
      setFormData({...formData, [value]:e[value], [name]:e});
    }
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

  const submitOss = async() =>{
    const tempData = formData;
    tempData.servicios = serviciosArray;

    setStatus({status: null, message: ""});
    setLoading(true);

    const request = await Put(`oss/${formData.id_oss}`,tempData) ;

    if(request.status === "success"){
      const doc = <PDFGenerator data={request.data} />;
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const formData2 = new FormData();
      formData2.append("file", blob);
      formData2.append("id_oss", request.data.id_oss);

      const request2 = await PostMulti('email',formData2) ;
    }

    setStatus(request);

    if(scrollRef.current){
        scrollRef.current.scrollIntoView({ 
             behavior: "smooth", 
          });
      }

    setLoading(false);
  }


	return (
        <MDBox pb={3} ref={scrollRef}>
          <Card>
              <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    Editar OSS N° {formData.id_oss}
                  </MDTypography>
                </Grid>
                <Grid item xs={6} textAlign="right" >
                  <MDButton variant="gradient" color="light" 
                    onClick={()=>setShow(true)}>
                    <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar a la lista
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Message status={status} />
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="text" label="No. Cotización" name="num_cotizacion" 
                            error={verifyError("num_cotizacion")} errorMsg={getError("num_cotizacion")}
                            value={formData.num_cotizacion} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="date" label="Fecha" name="fecha_oss"
                            error={verifyError("fecha_oss")} errorMsg={getError("fecha_oss")}
                            value={formData.fecha_oss} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox>
                          <MDInput type="text" label="Comentario" name="comentario"
                            error={verifyError("comentario")} errorMsg={getError("comentario")}
                            value={formData.comentario} onChange={handleChange}
                            multiline rows={5} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="text" label="Direccion" name="direccion" 
                            error={verifyError("direccion")} errorMsg={getError("direccion")}
                            value={formData.direccion} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="text" label="Estado" name="estado" 
                            error={verifyError("estado")} errorMsg={getError("estado")}
                            value={formData.estado} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={4} mt="auto">
                        <MDBox>
                          <MDInput type="text" label="Municipio" name="municipio" 
                            error={verifyError("municipio")} errorMsg={getError("municipio")}
                            value={formData.municipio} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MDBox mb={2}>
                          <MDTypography variant="h5" pb={1}>
                            Cliente
                          </MDTypography>
                          <small className="font-red">{getError("cliente")}</small>
                          {
                            formData.status_cotizacion === 0 ?
                              <Select className={`z-10 ${verifyError("cliente") ? "border-red" : ""}`}
                                options={clientes} placeholder="Cliente..."
                                value={formData.cliente} onChange={(e) => handleChange(e,"cliente","id_cliente")}
                                getOptionValue={(option) => `${option.id_cliente}`}
                                getOptionLabel={(option) => `${option.nombre_comercial}`}
                              />
                              :
                              <MDTypography variant="p" fontSize={15} pb={1}>
                                {formData.cliente_nombre}
                              </MDTypography>
                          }
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Servicios serviciosArray={serviciosArray} setServiciosArray={setServArr} 
                      catalogoServicios={servicios} errors={"errors" in status?status.errors:null}
                      setStatus={setStatus} typeForm="oss" editOss = {formData.status_cotizacion === 0 ? 'true' : 'false'} />
                    <Grid item xs={12}>
                      <MDBox mb={2}>
                        <MDInput type="text" label="Información Adicional" name="info_adicional"
                          error={verifyError("info_adicional")} errorMsg={getError("info_adicional")}
                          value={formData.info_adicional} onChange={handleChange}
                          multiline rows={5} fullWidth />
                      </MDBox>
                    </Grid>
                    <Divider />
                    {
                      // <Responsables responsableArray={responsableArray} setResponsableArray={setResponsableArray}/>
                    }
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MDBox mb={2}>
                          <MDTypography variant="h5" fontSize={14} pb={0} >
                            Comentarios adicionales
                          </MDTypography>
                          <hr />
                          <MDTypography mt={1} fontSize={13} >
                            {
                              formData.comentarios_0?.descripcion
                            }
                          </MDTypography>
                          <hr />
                          <MDTypography mt={1} fontSize={13} >
                            {
                              formData.comentarios_1?.descripcion
                            }
                          </MDTypography>
                          <hr />
                          <MDTypography mt={1} fontSize={13} >
                            {
                              formData.comentarios_2?.descripcion
                            }
                          </MDTypography>
                          <hr />
                          <MDTypography mt={1} fontSize={13} >
                            {
                              formData.otro_comentario
                            }
                          </MDTypography>
                          <hr />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox mb={2}>
                        <MDTypography variant="h5" pb={1}>
                          Responsables
                        </MDTypography>
                          <Select className={`z-10 ${verifyError("responsables") ? "border-red" : ""}`}
                            closeMenuOnSelect
                            components={animatedComponents}
                            isMulti
                            value={formData.responsables} onChange={(e) => handleChange(e, "responsables", "respArray")}
                            options={responsables}
                            getOptionValue={(option) => `${option.id_responsable}`}
                            getOptionLabel={(option) => `${option.nombre} - ${option.puesto}`}
                          />
                          <small className="font-red">{getError("responsables")}</small>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MDBox>
                          <MDTypography variant="h5" pb={1}>
                            Firma de Autorizacion
                          </MDTypography>
                          <MDInput type="text" label="Nombre de quien elabora" name="elabora"
                            error={verifyError("elabora")} errorMsg={getError("elabora")}
                            value={formData.elabora} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={6} mt="auto">
                        <MDBox>
                          <MDInput type="text" label="Puesto" name="elabora_puesto"
                            error={verifyError("elabora_puesto")} errorMsg={getError("elabora_puesto")}
                            value={formData.elabora_puesto} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid item xs={6}>
                        <MDBox>
                          <MDInput type="text" label="Nombre de quien acepta" name="acepta"
                            error={verifyError("acepta")} errorMsg={getError("acepta")}
                            value={formData.acepta} onChange={handleChange} fullWidth />
                        </MDBox>
                      </Grid>
                    </Grid>
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" 
                        onClick={() => submitOss()} fullWidth>
                        Guardar y Enviar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
        </MDBox>
	);
}

OssForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default OssForm;