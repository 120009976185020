import React, { useRef, useCallback, useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import Modal from 'react-modal';
import { CSVLink } from "react-csv";

import { Get, Put } from "tools/Helper";

import PropTypes from 'prop-types';
import QRCode from 'qrcode';

import { USER_ADMIN, USER_LIDER_PC, USER_INSTRUCTOR, USER_LIDER_CAPACITACION } from "constans";

import PDFGeneratorListaAsistencia from "tools/PDFGeneratorListaAsistencia";
import PDFGeneratorActaConstitutiva from "tools/PDFGeneratorActaConstitutiva";
import PDFGeneratorInformeFinal from "tools/PDFGeneratorInformeFinal";

import ReactPDF , { pdf, Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';
import Message from "../../tools/Message";
import FotosCurso from "./Fotos";

function Reportes({dataCursoProgramado, setDataCursoProgramado, setShow, setValueTab}) {
  const [status, setStatus] = useState({status: null, message: ""});
  const [domain, setDomain] = useState('');
  const [qrs, setQrs] = useState(null);
  const [urlCursos, setUrlCursos] = useState([]);
  const [data, setData] = useState([]);
  const [dataReporte, setDataReporte] = useState({});
  const [dataEncuesta, setDataEncuesta] = useState({});
  const [dataComentarios, setDataComentarios] = useState([]);
  const [dataReporteIsLoaded, setDataReporteIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cursoCerrado, setCursoCerrado] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [listCursantesPrograma, setListCursantesPrograma] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenReport, setModalIsOpenReport] = useState(false);

  const [imgChart1, setImgChart1] = useState(null);
  const [imgChart2, setImgChart2] = useState(null);

  const ref = useRef(null);
  const ref2 = useRef(null);

  const copyLink = (link) =>{
    navigator.clipboard.writeText(link);
    setStatus({status: 'success', message: 'Enlace copiado en portapapeles...'});
  }

  const generateQREvaluaciones = async () => {
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName= fullpath.replace(regex, '');
    setDomain(domainName);
    const urlsCursos = {
      urlDiagnostica: `${domainName}/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=diagnostica`,
      urlFinal: `${domainName}/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=final`,
      urlServicio: `${domainName}/evaluacion-curso?tk=${dataCursoProgramado.token_qr}&type=servicio`
    }
    setUrlCursos(urlsCursos);

    const qrDatas = [
      await QRCode.toDataURL(urlsCursos.urlDiagnostica),
      await QRCode.toDataURL(urlsCursos.urlFinal),
      await QRCode.toDataURL(urlsCursos.urlServicio)
    ]
    
    await setQrs(qrDatas);
  }

  const refreshData = () => {
    const newDataAdmin = [
      {
          num: 1,
          description: 'Lista asistencia',
          action: 'Boton',
          document: 'Lista',
          link: 'link',
          label: 'Generar PDF',
      },
      {
        num: 2,
        description: 'Acta constitutiva',
        action: 'Boton',
        document: 'Acta',
        link: 'link',
        label: 'Generar PDF',
      },
      {
        num: 3,
        description: 'Inventario Recursos Humanos',
        action: 'Boton',
        document: 'Humanos',
        link: 'link',
        label:  'Descargar XLS',
      },
      {
          num: 4,
          description: 'Evaluación diagnóstica',
          action: 'Boton',
          document: 'Documento',
          link: urlCursos.urlDiagnostica,
          qr: 0,
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Copiar Link',
      },
      {
          num: 5,
          description: 'Evaluación final',
          action: 'Boton',
          document: 'Documento',
          link: urlCursos.urlFinal,
          qr: 1,
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Copiar Link',
      },
      {
          num: 6,
          description: 'Evaluación de servicio',
          action: 'Boton',
          document: 'Documento',
          link: urlCursos.urlServicio,
          qr: 2,
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Copiar Link',
      },
      {
          num: 7,
          description: 'Fotografías del curso y comentarios',
          action: 'Boton',
          document: 'Documento',
          link: 'link',
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Subir',
      },
      {
          num: 8,
          description: 'Informe final del curso',
          action: 'Boton',
          document: 'Informe',
          link: 'link',
          label: dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Curso Cerrado' : 'Cerrar curso',
      },
    ];

    

    const newDataLiderPC = [
      {
          num: 1,
          description: 'Lista asistencia',
          action: 'Boton',
          document: 'Lista',
          link: 'link',
          label: dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Generar PDF',
      },
      {
        num: 2,
        description: 'Acta constitutiva',
        action: 'Boton',
        document: 'Acta',
        link: 'link',
        label: dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Generar PDF',
      },
      {
        num: 3,
        description: 'Inventario Recursos Humanos',
        action: 'Boton',
        document: 'Humanos',
        link: 'link',
        label:  'Descargar XLS',
      },
      {
          num: 4,
          description: 'Evaluación diagnóstica',
          action: 'Boton',
          document: 'Documento',
          link: urlCursos.urlDiagnostica,
          qr: 0,
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Copiar Link',
      },
      {
          num: 5,
          description: 'Evaluación final',
          action: 'Boton',
          document: 'Documento',
          link: urlCursos.urlFinal,
          qr: 1,
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Copiar Link',
      },
      {
          num: 6,
          description: 'Evaluación de servicio',
          action: 'Boton',
          document: 'Documento',
          link: urlCursos.urlServicio,
          qr: 2,
          label:  dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Cerrado' : 'Copiar Link',
      },
    ];

    const newDataCliente = [
      {
        num: 7,
        description: 'Informe final del curso',
        action: 'Boton',
        document: 'Informe',
        link: 'link',
        label: dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Curso Cerrado' : 'En curso',
      },
    ];

    const newDataCerrado = [
      {
        num: 3,
        description: 'Inventario Recursos Humanos',
        action: 'Boton',
        document: 'Humanos',
        link: 'link',
        label:  'Descargar XLS',
      },
      {
        num: 7,
        description: 'Informe final del curso',
        action: 'Boton',
        document: 'Informe',
        link: 'link',
        label: dataCursoProgramado.estatus === 'Completado' || cursoCerrado ? 'Curso Cerrado' : 'En curso',
      },
    ];

    if(parseInt(userData.user.admin, 10) === USER_ADMIN){
      setData(newDataAdmin);
    }

    if(parseInt(userData.user.admin, 10) === USER_LIDER_CAPACITACION){
      setData(newDataAdmin);
    }

    if(parseInt(userData.user.admin, 10) === USER_LIDER_PC){
      setData(newDataLiderPC);
    }

    if(parseInt(userData.user.admin, 10) === USER_INSTRUCTOR){
      setData(newDataAdmin);
    }

    if(userData.user.tipo === 'cliente'){
      setData(newDataCliente);
    }

    if(dataCursoProgramado.estatus === 'Completado'){
      setData(newDataCerrado);
    }

  }

  const headersReport = [
    {key: "nombre_completo", label: "Nombre" },
    {key: "curp", label: "Curp" },
    {key: "puesto", label: "Puesto" },
    {key: "puesto_brigada_cursante", label: "Puesto Brigada" },
    {key: "correo", label: "Correo" },
    {key: "telefono", label: "Teléfono" },
    {key: "edad", label: "Edad" },
    {key: "tipo_sangre", label: "Tipo de Sangre" },
    {key: "escolaridad", label: "Escolaridad" },
    {key: "direccion_completa", label: "Dirección" },
    {key: "nombre_emergencia", label: "Caso de Emergencia" },
    {key: "telefono_emergencia", label: "No. Teléfonico" }
  ];


  const downloadPDFListaAsistencia = async(row) => {
    const blob = await pdf(
      <PDFGeneratorListaAsistencia dataReporte = {dataReporte} />
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `lista_asistencia_${dataCursoProgramado.clave_curso_programado}.pdf`;
    link.click();
  }

  const downloadPDFActaConstitutiva = async(row) => {
    const blob = await pdf(
      <PDFGeneratorActaConstitutiva dataReporte = {dataReporte} />
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `acta_constitutiva_${dataCursoProgramado.clave_curso_programado}.pdf`;
    link.click();
  }

  const downloadXLSRecursosHumanos = () =>{
    console.log('Recursos')
  }

  const downloadPDFInformeFinal = async() => {
    const blob = await pdf(
      <PDFGeneratorInformeFinal dataReporte = {dataReporte} imgChart = {imgChart1} imgChartEncuesta = {imgChart2} dataComentarios = {dataComentarios}/>
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `informe_final_${dataCursoProgramado.clave_curso_programado}.pdf`;
    link.click();
  }

  const cerrarCursoProgramado = async () => {
    setStatus({status: 'primary', message: "Cerrando Curso..."});
    const newData = {
      ...dataCursoProgramado,
      estatus: 'Completado'
    }
    setDataCursoProgramado({
      ...dataCursoProgramado,
      estatus: 'Completado'
    });
    setCursoCerrado(true);
    const request =  await Put(`programar-curso/${dataCursoProgramado.id_curso_programado}`, newData) ;
    setStatus({status: 'success', message: "Curso cerrado"});
  }

  const [resultEvaParticipantes, setResultEvaParticipantes] = useState([]);
  const [resultEvaParticipantesDiag, setResultEvaParticipantesDiag] = useState([]);
  const [nombreEvaParticipantes, setNombreEvaParticipantes] = useState([]);
  const getDataEvaParticipantes =() => {
    const resultEvaDiag = [];
    const resultEva = [];
    const numCursante = [];
    if(dataReporte.cursante_programado){
      dataReporte.cursante_programado.forEach((item, index)=>{
        resultEvaDiag.push(item.calificacion_diagnostica);
        resultEva.push(item.calificacion_final);
        numCursante.push(item.cursante?.nombre_completo);
        // numCursante.push(index+1);
      });
      setResultEvaParticipantesDiag(resultEvaDiag);
      setResultEvaParticipantes(resultEva);
      setNombreEvaParticipantes(numCursante);
    }
  }

  const getDataParticipantesProgramaInterno =(dataParticipantesPrograma) => {
    const listCursantePrograma = [];
    if(dataParticipantesPrograma.cursante_programado){
      dataParticipantesPrograma.cursante_programado.forEach((cursateProgramado, index)=>{
        if(cursateProgramado.cursante){
          const direccionCompleta = `${cursateProgramado.cursante.calle_num ? cursateProgramado.cursante.calle_num : ''}, ${cursateProgramado.cursante.colonia ? cursateProgramado.cursante.colonia : ''}, ${cursateProgramado.cursante.municipio ? cursateProgramado.cursante.municipio : ''}, ${cursateProgramado.cursante.estado ? cursateProgramado.cursante.estado : ''}`
          const newData = {
            ...cursateProgramado.cursante,
            direccion_completa: direccionCompleta,
            puesto_brigada_cursante: cursateProgramado.cursante?.puesto_brigada?.descripcion
          }
          listCursantePrograma.push(newData);
        }
      });
    }
    setListCursantesPrograma(listCursantePrograma);
  }


  const getFinalReport = async ()=>{
    setStatus({status: null, message: ""});
    setDataReporteIsLoaded(false);
    setLoading(true);

    const request = await Get(`final-report/${dataCursoProgramado.id_curso_programado}`);
    
    if(request.status === "success"){
      setDataReporte(request.data.curso_programado);
      setDataEncuesta(request.data.encuestas);
      setDataComentarios(request.data.comentarios);
      getDataParticipantesProgramaInterno(request.data.curso_programado);
      setDataReporteIsLoaded(true);
    }else{
      setStatus({status:request.status, message:"Error."});
    }
    setLoading(false);
  }

  useEffect(()=>{
    getDataEvaParticipantes();
  },[dataReporte])

  useEffect(()=>{
    generateQREvaluaciones();
    getFinalReport();
  },[])

  useEffect(()=>{
    refreshData();
  },[qrs])

  const openModal = () => {
    setModalIsOpen(true);
  }
  const closeModal = () => {
    setModalIsOpen(false);
  }

  const closeModalReporr = () => {
    const fullpath = window.location.href;
    const regex = window.location.pathname;
    const domainName= fullpath.replace(regex, '');
    
    window.location.href = `${domainName}/cursos/programacion-cursos`;

    // setModalIsOpenReport(false);
    // setValueTab(0);
    // setShow(true);
  }

  const actionsButtom = (row) =>{
    if(row.label === 'Subir'){
        openModal();
    }

    if(row.label === 'Copiar Link'){
      copyLink(row.link)
    }

    if(row.label === 'Generar PDF' && row.document === 'Lista'){
      downloadPDFListaAsistencia(row)
    }

    if(row.label === 'Generar PDF' && row.document === 'Acta'){
      downloadPDFActaConstitutiva(row)
    }

    if(row.label === 'Descargar XLS' && row.document === 'Humanos'){
      downloadXLSRecursosHumanos(row)
    }

    if(row.label === 'Cerrar curso' && row.document === 'Informe'){
      const imgChart = ref.current.toBase64Image();
      const imgChartEncuesta = ref2.current.toBase64Image();
      setImgChart1(imgChart);
      setImgChart2(imgChartEncuesta);
      cerrarCursoProgramado();
      setModalIsOpenReport(true);
    }

  }

  const downloadFinalReport = async () => {
      const imgChart = ref.current.toBase64Image();
      const imgChartEncuesta = ref2.current.toBase64Image();
      const blob = await pdf(
        <PDFGeneratorInformeFinal dataReporte = {dataReporte} imgChart = {imgChart} imgChartEncuesta = {imgChartEncuesta} dataComentarios = {dataComentarios}/>
      ).toBlob();
  
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `informe_final_${dataCursoProgramado.clave_curso_programado}.pdf`;
      link.click();
  }

  const buttonPdf = (row) => 
    <MDButton onClick={()=>downloadFinalReport()} variant="gradient" color="info" iconOnly style={{ borderRadius: 10 }}>
        <Icon style={{ color: 'white',  fontSize: 20 }}>picture_as_pdf</Icon>
    </MDButton>;

  const buttonGeneral = (row) => {
    if(row.label === 'Descargar XLS')
        return (
          <MDButton variant="gradient" color="info" style={{ borderRadius: 8, padding: 10 }}>
            <CSVLink data={listCursantesPrograma} headers={headersReport} filename="Reporte_inventario_rh.csv" style=   {{color: 'white'}}> {row.label}
            </CSVLink>
        </MDButton>);
    
    return (<MDButton onClick={()=>actionsButtom(row)} variant="gradient" color={row.label === 'Curso Cerrado' ||  row.label === 'Cerrado' ? 'dark' : 'info'} style={{ borderRadius: 10, width: 100 }} >
        <MDTypography variant="p" display="inline" color="white">
            {row.label}
        </MDTypography>
    </MDButton>);

}

  const buttonQR = (row)=> (
     <img src={qrs ? qrs[row.qr] : ''} alt="qr" width={100}  />
  );

  const buttonLink = (row)=>
  <MDTypography variant="p" display="inline" >
    <a target="_blank" rel="noreferrer" href={row.link}>{row.link}</a>
  </MDTypography>

  const columns = [
    {
        name: 'No.',
        selector: row => row.num,
    },
    {
        name: 'Descripción',
        selector: row => row.description,
        wrap: true,
        grow:2
    },
    {
        name: '',
        selector: row => dataCursoProgramado.programa_interno === 'NO' && ( row.document === 'Acta' || row.document === 'Humanos') ?  'No aplica' : buttonGeneral(row),
        grow:2
    },
    {
      name: '',
      selector: row => row.label === 'Curso Cerrado' ? buttonPdf(row) : null,
    },
    // {
    //   name: '',
    //   selector: row => row.label === 'Curso Cerrado' ? buttonPdf(row) : null,
    // },
    {
      name: '',
      selector: row => row.label === 'Copiar Link' ? buttonLink(row) : null,
      wrap: true,
      grow:2
    },
    {
      name: '',
      selector: row => row.label === 'Copiar Link' ? buttonQR(row) : null,
    },
  ];

  const tableData = {
    columns,
    data
  };

  const dataChart = {
    labels: nombreEvaParticipantes,
    datasets: [
      {
        label: 'Evaluación Diagnóstica',
        data: resultEvaParticipantesDiag,
        backgroundColor: [
          'rgba(44, 132, 248)',
        ],
        borderWidth: 1,
      },
      {
        label: 'Evaluación final',
        data: resultEvaParticipantes,
        backgroundColor: [
          'rgba(67, 158, 64)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const dataChartEncuesta = {
    labels: dataEncuesta ? dataEncuesta.pregunta : null,
    datasets: [
      {
        label: '# of Resultados',
        data: dataEncuesta ? dataEncuesta.valor : null,
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
          'rgba(255, 206, 86)',
          'rgba(75, 192, 192)',
          'rgba(153, 102, 255)',
          'rgba(255, 159, 64)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const optionsChart = {
    scales: {
      y: {
        min: 0,
        max: 10,
      }
    },
  }

  return (
  <MDBox>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Message status={status} />
        <Bar style={{ display: 'none' }} ref={ref2} data={dataChartEncuesta} options={optionsChart} />
        <Bar style={{ display: 'none' }} ref={ref} data={dataChart} options={optionsChart} />
        <MDBox>
          <DataTableExtensions
            {...tableData}
            export={false}
            print={false}
            filterPlaceholder="Buscar..."
          >
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: 'Participantes por página:', 
                rangeSeparatorText: 'de', 
                noRowsPerPage: false, 
                selectAllRowsItem: true, 
                selectAllRowsItemText: 'Todos'
              }}
              noDataComponent={<MDBox py={5}>No se encontraron reportes.</MDBox>}
            />
          </DataTableExtensions>
        </MDBox>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Modal Fotografía"
        >
        <Grid container spacing={6} pb={2}>
            <Grid item xs={12} spacing={6} pb={2}>
                <FotosCurso dataCursoProgramado = {dataCursoProgramado} getFinalReport = {getFinalReport} setDataCursoProgramado = {setDataCursoProgramado}/>
            </Grid>
            <Grid item xs={12} textAlign="right" >
                <MDButton style={{ borderRadius: 10, color: 'white' }} variant="gradient" color="dark" fullWidth onClick={()=>closeModal()}>
                    Cerrar
                </MDButton>
            </Grid>
        </Grid>
        </Modal>
        <Modal
          isOpen={modalIsOpenReport}
          onRequestClose={closeModalReporr}
          contentLabel="Modal Certificado"
        >
        <Grid container spacing={6} pb={2}>
          <Grid item xs={6}>
            <MDButton variant="gradient" style={{ color: 'white' }} color="error" fullWidth onClick={()=>closeModalReporr()}>
              Cerrar
            </MDButton>
          </Grid>
          <Grid item xs={6} textAlign="right" >
            <MDButton variant="gradient" style={{ color: 'white' }} color="success" fullWidth onClick={()=>downloadPDFInformeFinal()}>
              Descargar
            </MDButton>
          </Grid>
        </Grid>
        {
          dataReporteIsLoaded && imgChart1 && imgChart2 ?
            <PDFViewer width="100%" height="96%" scale="3.0">
              <PDFGeneratorInformeFinal dataReporte = {dataReporte} imgChart = {imgChart1} imgChartEncuesta = {imgChart2} dataComentarios = {dataComentarios}/>
            </PDFViewer>
          : "Cargando..."
        }
        </Modal>
      </Grid>
    </Grid>
  </MDBox>
  );
}

Reportes.propTypes = {
  dataCursoProgramado: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  setDataCursoProgramado: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  setValueTab: PropTypes.func.isRequired,
};

export default Reportes;
